import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionBox from "./QuestionBox.js";
import FinalQuestionBox from "./FinalQuestionBox";
import "./Question.scss";
import Paper from "@material-ui/core/Paper";
import validator from "validator";
import { setPersistedValue } from "../../../store/actions.js";
const getId = (object, name) => {
  return Object.keys(object).find((key) => object[key] === name);
};
const nullValueItems = [37, 43, 44, 46, 52, 63, 64, 65, 66, 83];
const nullValuesForItems = {
  37: "I don't receive any enquiries",
  43: "It's work in progress",
  44: [
    "Not sure where to start with intellectual property protection.",
    "Not sure how to protect our intellectual property overseas.",
  ],
  46: "This is not relevant to our business, we don't need compliance.",
  52: ["No it is not required.", "Not sure"],
  63: "No never",
  64: "No we are not",
  65: "No nothing else",
  66: "Not yet",
  83: "We don't do sales forecast",
};
export default function Questions(props) {
  const { persistedValue } = useSelector((params) => params);
  const dispatch = useDispatch();
  const { fields, currentQuestion, activePage, continueStatus, updateSubmit } =
    props;
  const [value, setValue] = useState("");
  const [notValid, setNotValid] = useState(false);
  let localStorageData = localStorage.getItem("exportiaUserData");
  localStorageData = JSON.parse(localStorageData);
  const updatePersistedValue = (data, subValues = false) => {
    let updateData = persistedValue;
    let key = "";
    let value = "";
    if (subValues) {
      updateData = persistedValue[data.id];
      key = data.subId;
      value = data.value;
    } else {
      key = data[0];
      value = data[1];
    }
    return Object.assign(updateData, {
      ...updateData,
      [key]: value,
    });
  };
  function handleChange(event, id, type = false, subId = false) {
    event.preventDefault();
    let value = event.target.value;
    let tempKey = null;
    // console.log(id, type, subId, value);
    let newData = {};
    if (type === "multiselect") {
      if (event.target.checked) {
        if (persistedValue[id]) {
          value = [...persistedValue[id], value];
        } else {
          value = [value];
        }
      } else {
        value = persistedValue[id].filter((item) => item !== value);
      }
      if (currentQuestion === 1 && activePage === 1) {
        newData = updatePersistedValue(["remove_country", false]);
      }
    } else if (type === "select") {
      setValue(event.target.value);
      if (event.target.checked) {
        if (persistedValue[id] && Object.keys(persistedValue[id]).length > 0) {
          if (nullValueItems.includes(id)) {
            let identifier = nullValuesForItems[id];
            if (typeof identifier === "string") {
              if (value === identifier) {
                value = { [subId]: value };
              } else {
                tempKey = getId(persistedValue[id], identifier);
                if (tempKey) delete persistedValue[id][tempKey];
                value = updatePersistedValue(
                  { id: id, subId: subId, value: value },
                  true
                );
              }
            } else {
              Object.keys(persistedValue[id]).map((item) => {
                let tempVal = persistedValue[id][item];
                if (
                  identifier.includes(value) &&
                  !identifier.includes(tempVal)
                ) {
                  delete persistedValue[id][item];
                } else if (
                  !identifier.includes(value) &&
                  identifier.includes(tempVal)
                ) {
                  delete persistedValue[id][item];
                }
              });
              value = updatePersistedValue(
                { id: id, subId: subId, value: value },
                true
              );
            }
          } else {
            value = updatePersistedValue(
              { id: id, subId: subId, value: value },
              true
            );
          }
        } else {
          value = { [subId]: value };
        }
      } else {
        let arr = persistedValue[id];
        delete arr[subId];
        value = arr;
      }
      if (currentQuestion === 2 && activePage === 1) {
        newData = Object.assign(persistedValue, {
          ...persistedValue,
          remove_all_country: false,
        });
      }
    } else if (type === "remove-all") {
      value = [];
      newData = updatePersistedValue(["remove_country", true]);
    } else if (type === "remove-all-country") {
      newData = updatePersistedValue(["remove_all_country", true]);
    }
    if (type === "remove-all-country") {
      // console.log(type);
      let dummyPersistedData = persistedValue;
      let keys = Object.keys(dummyPersistedData);
      keys.map((x) => {
        if (
          x === "20" ||
          x === "22" ||
          x === "23" ||
          x === "24" ||
          x === "25" ||
          x === "26" ||
          x === "27"
        ) {
          dummyPersistedData[x] = {};
          // console.log(dummyPersistedData[x]);
        }
      });
      dispatch({ type: setPersistedValue, persistedValue: dummyPersistedData });
    } else {
      newData = {
        ...persistedValue,
        [id]: value,
      };
      if (id === 51 && value === "") {
        newData = {
          ...persistedValue,
          [id]: "other",
        };
      }
      dispatch({ type: setPersistedValue, persistedValue: newData });
    }
    localStorage.setItem("exportiaUserData", JSON.stringify(newData));
  }
  useEffect(() => {
    let localStorageData = JSON.parse(localStorage.getItem("exportiaUserData"));
    if (localStorageData && Object.keys(localStorageData).length) {
      if (localStorageData[88]) {
        if (
          !validator.isEmail(localStorageData[88]) &&
          localStorageData[88] !== ""
        ) {
          delete localStorageData[88];
          setNotValid(true);
        }
      }
      if (localStorageData[92]) {
        const regex =
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm;

        if (!regex.test(localStorageData[92]) || !localStorageData[92]) {
          delete localStorageData[92];
          setNotValid(true);
        }
      }
      if (localStorageData[101]) {
        const re = /^[+]?\d+$/;
        if (!re.test(localStorageData[101]) || !localStorageData[101]) {
          delete localStorageData[101];
          setNotValid(true);
        }
      }
      dispatch({ type: setPersistedValue, persistedValue: localStorageData });
      localStorage.setItem(
        "exportiaUserData",
        JSON.stringify(localStorageData)
      );
    } else {
      dispatch({ type: setPersistedValue, persistedValue: { form_id: "1" } });
      localStorage.setItem("exportiaUserData", JSON.stringify({}));
    }
  }, []);
  useEffect(() => {
    dispatch({
      type: setPersistedValue,
      persistedValue: JSON.parse(localStorage.getItem("exportiaUserData")),
    });
  }, [localStorage.getItem("exportiaUserData")]);
  return (
    <section className="stepper-form-wrapper">
      <div className="container">
        <Paper elevation={3} className="form-container bg-white">
          <div className="form">
            {activePage !== 7 ? (
              fields.map((item, index) =>
                index === currentQuestion ? (
                  item.constructor === Array ? (
                    item.map((subItem, subIndex) => (
                      <QuestionBox
                        key={subIndex}
                        currentQuestion={currentQuestion}
                        activePage={activePage}
                        fieldData={subItem}
                        handleChange={handleChange}
                        persistedValue={persistedValue}
                        continueStatus={continueStatus}
                      />
                    ))
                  ) : (
                    <QuestionBox
                      currentQuestion={currentQuestion}
                      activePage={activePage}
                      key={index}
                      fieldData={item}
                      handleChange={handleChange}
                      persistedValue={persistedValue}
                      continueStatus={continueStatus}
                    />
                  )
                ) : (
                  ""
                )
              )
            ) : (
              <div className="contact-details-wrapper">
                {fields.map((item, index) => (
                  <FinalQuestionBox
                    key={index}
                    fieldData={item}
                    notValid={notValid}
                    handleChange={handleChange}
                    persistedValue={persistedValue}
                    continueStatus={continueStatus}
                  />
                ))}
              </div>
            )}
          </div>
        </Paper>
      </div>
    </section>
  );
}
