import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";

function Loader() {
  const { loading } = useSelector((params) => params);
  return (
    <div className="loader">
      <CircularProgress />
    </div>
  );
}

export default Loader;
