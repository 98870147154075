import { Paper } from "@material-ui/core";
import React from "react";
import "./SubmitSuccess.scss";

const SubmitSuccess = (props) => {
  const [submitSuccess, setSubmitSuccess] = React.useState(true);
  const { generalSettings } = props;
  return (
    <div className="submit-success-form-wrapper">
      <div className="container">
        <Paper
          elevation={3}
          className="form-container bg-white submit-success-submiteed"
        >
          {submitSuccess === true ? (
            <>
              {generalSettings.formSubmitMessages &&
              generalSettings.formSubmitMessages.successMessage ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: generalSettings.formSubmitMessages.successMessage,
                  }}
                />
              ) : (
                <div>
                  <h2 className="h3">Form submission successful!</h2>
                  <p className="heading1">
                    Thank you for completing the Go Export Ready assessment
                    form. An email will be sent to you in a few minutes with
                    your results and further information to get you started.
                    <br />
                    <br />
                    Exportia
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              {generalSettings.formSubmitMessages &&
              generalSettings.formSubmitMessages.failedMessage ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: generalSettings.formSubmitMessages.failedMessage,
                  }}
                />
              ) : (
                <div>
                  <h2 className="h3">Form submission failed!</h2>
                  <p className="heading1">
                    Opps, something has gone wrong <br />
                    Exportia
                  </p>
                </div>
              )}
            </>
          )}
          {localStorage.setItem(
            "exportiaNavData",
            JSON.stringify({ currentPage: 0, currentQuestion: 0 }),
            localStorage.removeItem("exportiaUserData")
          )}
        </Paper>
      </div>
    </div>
  );
};

export default SubmitSuccess;
