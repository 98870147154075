import React, { useEffect } from "react";
import axios from "axios";
import { Buffer } from "buffer";
import { useSearchParams } from "react-router-dom";
import {
  setGeneralSettings,
  setFormData,
  setLoadHomePage,
  setCurrentPage,
  setCurrentQuestion,
} from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import LandingPage from "./Components/LandingPage";
import StepperForm from "./Components/StepperForm";
import EmailUs from "./Components/EmailUs";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Loader from "./Components/Loader";

export default function Main() {
  const dispatch = useDispatch();
  const [searchlocation] = useSearchParams();
  const {
    generalSettings,
    formData,
    loadHomePage,
    currentPage,
    currentQuestion,
  } = useSelector((params) => params);
  useEffect(() => {
    // Check if continue data
    let locationId = searchlocation.get("id");
    let locationNonce = searchlocation.get("nonce");
    if (locationId && locationNonce) {
      const url = process.env.REACT_APP_CUSTOM_URL_BASE + "continue-data";
      axios({
        method: "post",
        url: url,
        data: { id: locationId, nonce: locationNonce },
      })
        .then(function (response) {
          if (response.status === 200) {
            let resultData = response.data;
            if (resultData.status === true) {
              localStorage.setItem("exportiaUserData", resultData.data);
              localStorage.setItem("exportiaNavData", resultData.location);
            }
          }
          window.location.href = window.location.origin;
        })
        .catch(function (response) {
          // setResponseData(response.message);
        });
    }
    // Get form data
    const username = process.env.REACT_APP_REST_API_USERNAME;
    const password = process.env.REACT_APP_REST_API_PASSWORD;
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    const url = process.env.REACT_APP_GFORM_URL;
    axios({
      method: "get",
      url: url,
      headers: { Authorization: `Basic ${token}` },
    })
      .then(function (response) {
        dispatch({ type: setFormData, formData: response.data });
      })
      .catch(function (response) {
        dispatch({ type: setFormData, formData: response.message });
      });
    // Get general settings data
    const customUsername = process.env.REACT_APP_CUSTOM_REST_API_USERNAME;
    const customUPassword = process.env.REACT_APP_CUSTOM_REST_API_PASSWORD;
    const customToken = Buffer.from(
      `${customUsername}:${customUPassword}`,
      "utf8"
    ).toString("base64");
    const customBaseUrl = process.env.REACT_APP_CUSTOM_URL_BASE;
    axios({
      method: "get",
      url: customBaseUrl + "general-settings",
      headers: { Authorization: `Basic ${customToken}` },
    })
      .then(function (response) {
        dispatch({
          type: setGeneralSettings,
          generalSettings: response.data,
        });
      })
      .catch(function (response) {
        dispatch({
          type: setGeneralSettings,
          generalSettings: response.message,
        });
      });
    // Change page if current question or current page is available
    if (currentPage !== 0 || currentQuestion !== 0) {
      dispatch({ type: setLoadHomePage, loadHomePage: false });
    }
  }, []);
  return (
    <>
      {formData?.title && generalSettings?.siteTitle ? (
        <>
          <Navbar />
          {loadHomePage ? (
            <LandingPage />
          ) : (
            <>
              <StepperForm />
              <EmailUs />
            </>
          )}
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
