import React, { useState } from "react";
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  FormHelperText,
} from "@material-ui/core";
import validator from "validator";
import radioButton from "../../../assets/icons/radio-button.svg";
import radioChecked from "../../../assets/icons/radio-checked.svg";
import toolTip from "../../../assets/icons/tooltip.svg";
import { setSubmitAction } from "../../../store/actions";
import { useDispatch } from "react-redux";
export default function QuestionBox(props) {
  var { persistedValue, continueStatus, fieldData, updateSubmit } = props;
  const dispatch = useDispatch();
  const [close, setClose] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [urlError, setUrlError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email) || email === "") {
      setEmailError("");
    } else {
      setEmailError("Invalid email address.");
      dispatch({ type: setSubmitAction, submitAction: false });
      return;
    }
  };
  // regex url validation
  const validateUrl = (e) => {
    var url = e.target.value;
    const regex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm;

    if (regex.test(url) || !url) {
      setUrlError("");
    } else {
      setUrlError("Invalid website url.");
      dispatch({ type: setSubmitAction, submitAction: false });
      return;
    }
  };

  const validatePhone = (e) => {
    const re = /^[+]?\d+$/;
    var phone = e.target.value;

    if (re.test(phone) || !phone) {
      setPhoneError("");
    } else {
      setPhoneError(
        "Phone number must be number only and should include country code."
      );
      dispatch({ type: setSubmitAction, submitAction: false });
      return;
    }
  };

  function createFields(fieldData) {
    switch (fieldData.type) {
      case "radio":
        return (
          <RadioGroup
            aria-label={fieldData.adminLabel}
            name={fieldData.adminLabel}
            className="radio-label"
            value={persistedValue[fieldData.id] || ""}
            onChange={(event) => props.handleChange(event, fieldData.id)}
          >
            {fieldData.choices.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={
                  <Radio
                    color="primary"
                    icon={
                      <img
                        src={radioButton}
                        className="checkbox-icon"
                        alt="radio-button-icon"
                      />
                    }
                    checkedIcon={
                      <img
                        src={radioChecked}
                        className="checkbox-icon"
                        alt="radio-button-checked-icon"
                      />
                    }
                  />
                }
                label={item.text}
                className={"radio-options form-fields"}
              />
            ))}
          </RadioGroup>
        );

      case "text":
        return (
          <div className="d-flex flex-wrap align-items-center pos-relative">
            <TextField
              label={fieldData.label}
              variant="outlined"
              className="w-100"
              onChange={(e) => props.handleChange(e, fieldData.id)}
              value={
                persistedValue[fieldData.id] &&
                persistedValue[fieldData.id].length
                  ? persistedValue[fieldData.id]
                  : ""
              }
              error={
                fieldData.isRequired &&
                !continueStatus &&
                !persistedValue[fieldData.id]
                  ? true
                  : false
              }
              helperText={
                fieldData.isRequired &&
                !continueStatus &&
                !persistedValue[fieldData.id] &&
                "This field is required"
              }
            />

            {fieldData.description ? (
              <Tooltip title={fieldData.description} placement="top" arrow>
                <img
                  src={toolTip}
                  className="tooltip"
                  alt="tooltip"
                  height="24"
                  width="24"
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );

      case "phone":
        return (
          <div className="d-flex flex-wrap align-items-center pos-relative">
            {fieldData.label === "Phone" && (
              <span
                className={phoneError === "" ? "d-none" : "error-phone"}
                style={{
                  color: "red",
                  order: "2",
                }}
              >
                {phoneError}
              </span>
            )}
            <TextField
              label={fieldData.label}
              variant="outlined"
              type="text"
              className="w-100"
              onInput={(e) => validatePhone(e)}
              onChange={(e) => props.handleChange(e, fieldData.id)}
              value={
                persistedValue[fieldData.id] &&
                persistedValue[fieldData.id].length
                  ? persistedValue[fieldData.id]
                  : ""
              }
              error={
                fieldData.isRequired &&
                !continueStatus &&
                !persistedValue[fieldData.id]
                  ? true
                  : false
              }
              helperText={
                fieldData.isRequired &&
                !continueStatus &&
                !persistedValue[fieldData.id] &&
                "This field is required"
              }
            />

            {fieldData.description ? (
              <Tooltip title={fieldData.description} placement="top" arrow>
                <img
                  src={toolTip}
                  className="tooltip"
                  alt="tooltip"
                  height="24"
                  width="24"
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );

      case "website":
        return (
          <div className="d-flex flex-wrap align-items-center pos-relative">
            {
              <span
                className={urlError === "" ? "d-none" : "error-url"}
                style={{
                  color: "red",
                  order: "2",
                }}
              >
                {urlError}
              </span>
            }
            <TextField
              label={fieldData.label}
              variant="outlined"
              className="w-100"
              onInput={(e) => validateUrl(e)}
              onChange={(e) => props.handleChange(e, fieldData.id)}
              value={
                persistedValue[fieldData.id] &&
                persistedValue[fieldData.id].length
                  ? persistedValue[fieldData.id]
                  : ""
              }
              error={
                fieldData.isRequired &&
                !continueStatus &&
                (!persistedValue[fieldData.id] ||
                  persistedValue[fieldData.id].length < 1)
                  ? true
                  : false
              }
              helperText={
                fieldData.isRequired &&
                !continueStatus &&
                !persistedValue[fieldData.id] &&
                "This field is required"
              }
            />
            {fieldData.description ? (
              <Tooltip title={fieldData.description} placement="top" arrow>
                <img
                  src={toolTip}
                  className="tooltip"
                  alt="tooltip"
                  height="24"
                  width="24"
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );

      case "html":
        return <p className="html-content">{fieldData.content}</p>;

      case "select":
        return (
          <div className="d-flex flex-wrap align-items-center pos-relative">
            {fieldData.label === "Country Location of HQ" ? (
              <FormHelperText
                className="country-hq-error"
                style={{
                  order: "2",
                }}
                error={
                  fieldData.isRequired &&
                  !continueStatus &&
                  !persistedValue[fieldData.id]
                    ? true
                    : false
                }
              >
                This field is required
              </FormHelperText>
            ) : (
              ""
            )}
            <Select
              label={fieldData.label}
              onChange={(e) => props.handleChange(e, fieldData.id)}
              onClose={() => {
                setClose(!close);
              }}
              value={
                persistedValue[fieldData.id] &&
                persistedValue[fieldData.id].length
                  ? persistedValue[fieldData.id]
                  : ""
              }
              className={
                fieldData.label === "Country Location of HQ" &&
                setClose !== false
                  ? "select-field w-100 country-hq"
                  : "select-field w-100"
              }
            >
              {fieldData.choices.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
            {fieldData.description ? (
              <Tooltip title={fieldData.description} placement="top" arrow>
                <img
                  src={toolTip}
                  className="tooltip"
                  alt="tooltip"
                  height="24"
                  width="24"
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      case "email":
        return (
          <div className="d-flex flex-wrap align-items-center pos-relative">
            {
              <span
                className={emailError === "" ? "d-none" : "error-email"}
                style={{
                  color: "red",
                  order: "2",
                }}
              >
                {emailError}
              </span>
            }
            <TextField
              label={fieldData.label}
              variant="outlined"
              className="w-100"
              onInput={(e) => validateEmail(e)}
              onChange={(e) => props.handleChange(e, fieldData.id)}
              value={
                persistedValue[fieldData.id] &&
                persistedValue[fieldData.id].length
                  ? persistedValue[fieldData.id]
                  : ""
              }
              error={
                fieldData.isRequired &&
                !continueStatus &&
                (!persistedValue[fieldData.id] ||
                  persistedValue[fieldData.id].length < 1)
                  ? true
                  : false
              }
              helperText={
                fieldData.isRequired &&
                !continueStatus &&
                !persistedValue[fieldData.id] &&
                "This field is required"
              }
            />
            {fieldData.description ? (
              <Tooltip title={fieldData.description} placement="top" arrow>
                <img
                  src={toolTip}
                  className="tooltip"
                  alt="tooltip"
                  height="24"
                  width="24"
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      default:
        return "";
    }
  }
  return (
    <>
      {fieldData.type === "select" ? (
        <FormControl
          component="fieldset"
          variant="outlined"
          error
          className={
            "last-page-" +
            fieldData.adminLabel +
            " last-page-type-" +
            fieldData.type
          }
        >
          <InputLabel className="select-field-label">
            {fieldData.label}
          </InputLabel>
          {createFields(fieldData)}
        </FormControl>
      ) : (
        <FormControl
          component="fieldset"
          className={
            "last-page-" +
            fieldData.adminLabel +
            " last-page-type-" +
            fieldData.type
          }
        >
          {(fieldData.type === "html" || fieldData.type === "radio") && (
            <>
              <div className="d-flex align-items-center">
                <label className="h3">{fieldData.label}</label>
                {fieldData.description && (
                  <Tooltip title={fieldData.description} placement="top" arrow>
                    <img
                      src={toolTip}
                      className="tooltip label-placement"
                      alt="tooltip"
                      height="24"
                      width="24"
                    />
                  </Tooltip>
                )}
              </div>
              {fieldData.isRequired &&
                !continueStatus &&
                !persistedValue[fieldData.id] && (
                  <p className="required-text final-radio-error-text">
                    This field is required
                  </p>
                )}
            </>
          )}
          {createFields(fieldData)}
        </FormControl>
      )}
    </>
  );
}
