import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions";
import { skipLogicData, skipLogicId } from "./customFormRestrictions";
export const getSalesFields = (currentQuestion) => {
  let localStorageData = JSON.parse(localStorage.getItem("exportiaUserData"));
  let allId = skipLogicId["all"];
  let dummyCurrentQuestion = currentQuestion;
  let returnArray = skipLogicData[localStorageData[50]];
  let returnId = skipLogicId[localStorageData[50]];
  if (
    localStorageData[50] ===
      "We use distributors to sell (no direct sales to end-users)." ||
    localStorageData[50] ===
      "We sell direct, we have distributors and we also sell online" ||
    localStorageData[50] ===
      "We do both sell direct and have distributors as well."
  ) {
    if (
      localStorageData[55] === "Yes we have one established" ||
      localStorageData[55] === "Yes we have established more than one"
    ) {
      if (!returnArray.includes(6)) returnArray.splice(5, 0, 6);
      if (!returnId.includes(56)) returnId.push(56);
    } else {
      const index = returnArray.indexOf(6);
      if (index > -1) {
        returnArray.splice(index, 1);
      }
      const indexNew = returnId.indexOf(56);
      if (indexNew > -1) {
        returnId.splice(indexNew, 1);
      }
    }
  }
  let removeIndexData = allId.filter((x) => !returnId.includes(x));
  removeIndexData.map((id) => {
    if (localStorageData[id]) delete localStorageData[id];
  });
  localStorage.setItem("exportiaUserData", JSON.stringify(localStorageData));
  return returnArray;
};
const continueStatusValidationbyId = (localStorageData, id) => {
  if (id === 20) {
    if (
      (localStorageData[20] && Object.keys(localStorageData[20]).length > 0) ||
      (localStorageData[22] && Object.keys(localStorageData[22]).length > 0) ||
      (localStorageData[23] && Object.keys(localStorageData[23]).length > 0) ||
      (localStorageData[24] && Object.keys(localStorageData[24]).length > 0) ||
      (localStorageData[25] && Object.keys(localStorageData[25]).length > 0) ||
      (localStorageData[26] && Object.keys(localStorageData[26]).length > 0) ||
      (localStorageData[27] && Object.keys(localStorageData[27]).length > 0) ||
      (localStorageData["remove_all_country"] &&
        localStorageData["remove_all_country"] === true)
    ) {
      return true;
    } else {
      return false;
    }
  } else if (id === 17) {
    if (
      (Object.keys(localStorageData).includes("17") &&
        localStorageData[17].length > 0) ||
      (Object.keys(localStorageData).includes("remove_country") &&
        localStorageData["remove_country"] === true)
    ) {
      return true;
    } else {
      return false;
    }
  }
};
const continueStatusValidationbyType = (
  localStorageData,
  propsArray,
  isArray = false
) => {
  let { fields, currentPage, currentQuestion } = propsArray;
  if (isArray) {
    let found = fields.some(
      (t) =>
        t.isRequired &&
        !Object.keys(localStorageData).includes(t.id.toString()) &&
        !Object.keys(t.label).length > 0
    );
    if (found) {
      return false;
    } else {
      return true;
    }
  } else {
    if (fields.isRequired) {
      let label = fields.id;
      if (
        localStorageData[label] &&
        Object.keys(localStorageData[label]).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        fields.enableOtherChoice === true &&
        localStorageData[fields.id] === "other"
      ) {
        return false;
      } else {
        return true;
      }
      if (
        currentQuestion === 1 &&
        currentPage === 1 &&
        localStorageData[17] &&
        Object.keys(localStorageData[17]).length > 1
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
};
export const HandleNavigation = (params) => {
  // debugger;
  const { currentPage, currentQuestion, currentAction, fields, formData } =
    params;
  let returnData = {
    continueStatus: true,
    question: currentQuestion,
    page: currentPage,
  };
  let localStorageData = JSON.parse(localStorage.getItem("exportiaUserData"));
  // Navigation for back button
  if (currentAction === "prev") {
    // Check if is 1st question
    if (currentPage === 3 && currentQuestion !== 0) {
      let dummyCurrentQuestion = currentQuestion;
      let skipLogicArray = getSalesFields(currentQuestion);
      skipLogicArray.find((value, index) => {
        if (value === currentQuestion) {
          dummyCurrentQuestion = index;
        }
      });
      returnData.question = skipLogicArray[dummyCurrentQuestion - 1];
    } else if (currentQuestion !== 0) {
      returnData.question = currentQuestion - 1;
    } else {
      /**
       *  Lower page number if 1st question.
       *  Back button for 1st page 1st question is disabled
       */
      returnData.page = currentPage - 1;
    }
    returnData.continueStatus = true;
  } else if (currentAction === "next") {
    // Navigation for next button
    /**
     * Check if is 1st or 2nd question for 1st page
     * Used this to make sure none button can be selected
     */
    if (currentPage === 3) {
      if (localStorageData[50]) {
        let dummyCurrentQuestion = currentQuestion;
        let skipLogicArray = getSalesFields(currentQuestion);
        // console.log(skipLogicArray);
        skipLogicArray.find((value, index) => {
          if (value === currentQuestion) {
            dummyCurrentQuestion = index;
          }
        });
        // console.log(skipLogicArray);
        if (dummyCurrentQuestion === skipLogicArray.length - 1) {
          returnData.page = currentPage + 1;
          returnData.question = 0;
        } else {
          returnData.question = skipLogicArray[dummyCurrentQuestion + 1];
        }
        returnData.continueStatus = continueStatusValidationbyType(
          localStorageData,
          {
            fields: fields[currentQuestion],
            currentPage: currentPage,
            currentQuestion: currentQuestion,
          }
        );
      } else {
        returnData.continueStatus = false;
      }
    } else if (
      currentPage === 1 &&
      (currentQuestion === 1 || currentQuestion === 2)
    ) {
      let questionId = 20;
      if (currentQuestion === 1) {
        questionId = 17;
      }
      let testStatus = continueStatusValidationbyId(
        localStorageData,
        questionId
      );
      returnData.continueStatus = testStatus;
      if (testStatus) {
        returnData.question = currentQuestion + 1;
      }
    } else {
      let type = false;
      const isArray = Array.isArray(fields[currentQuestion]);
      if (isArray) {
        type = true;
      }
      returnData.continueStatus = continueStatusValidationbyType(
        localStorageData,
        {
          fields: fields[currentQuestion],
          currentPage: currentPage,
          currentQuestion: currentQuestion,
        },
        type
      );
      if (currentQuestion === fields.length - 1) {
        var formPages = formData?.pagination?.pages;
        if (currentPage === formPages.length - 1) {
          return "form submit goes here";
        } else {
          returnData.page = currentPage + 1;
          returnData.question = 0;
        }
      } else {
        returnData.question = currentQuestion + 1;
      }
    }
  }
  return returnData;
};
export const HandleDataPreparation = (testFormFields) => {
  let formFieldsRaw = [];
  let count = 0;
  let group = false;
  Object.keys(testFormFields).forEach(function (key) {
    let item = testFormFields[key];
    if (
      item.cssClass.length > 0 &&
      item.cssClass.includes("group-items") &&
      group === true
    ) {
      count++;
      group = false;
    }
    if (item.type === "html") {
      group = true;
      if (formFieldsRaw[count]) {
        formFieldsRaw[count] = [...formFieldsRaw[count], item];
      } else {
        formFieldsRaw[count] = [item];
      }
    } else if (
      item.cssClass.length > 0 &&
      item.cssClass.includes("accordion-item")
    ) {
      group = true;
      if (formFieldsRaw[count]) {
        formFieldsRaw[count] = [...formFieldsRaw[count], item];
      } else {
        formFieldsRaw[count] = [item];
      }
    } else {
      if (group === true) {
        if (formFieldsRaw[count] && formFieldsRaw[count].length > 0) {
          formFieldsRaw[count] = [...formFieldsRaw[count], item];
        }
        group = false;
      } else {
        formFieldsRaw[count] = item;
      }
      count++;
    }
  });
  return formFieldsRaw;
};
