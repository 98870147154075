import "./QuestionNavigation.scss";

export default function QuestionNavigation(props) {
  const { generalSettings } = props;
  const navImages = generalSettings.navigationImages;
  function isCompleted(index) {
    if (props.activePage > index) {
      return "visited";
    } else {
      return "";
    }
  }
  return (
    <>
      {navImages.length > 0 && (
        <section className="stepper-form-navigation">
          <div className="stepper-form-navigation-container container">
            <ul className="stepper-form-navigation-list">
              {navImages.length > 0 && props.navList.length
                ? props.navList.map((item, index) => (
                    <li
                      key={item}
                      className={
                        index === props.activePage
                          ? "active"
                          : isCompleted(index)
                      }
                    >
                      <figure>
                        <img src={navImages[index]} alt="{item}" />
                      </figure>
                      <span>{item}</span>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        </section>
      )}
    </>
  );
}
