import "./App.scss";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Main from "./Main.js";
import { Routes, Route } from "react-router";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/">
            <Route index element={<Main />} />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}
export default App;
