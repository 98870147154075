import { useState, useEffect } from "react";
import axios from "axios";
import { Buffer } from "buffer";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import QuestionNavigation from "./QuestionNavigation";
import Loader from "../Loader";
import "./Form.scss";
import Questions from "./Questions";
import QuestionFooter from "./QuestionFooter";
import FinishLater from "../FinishLater";
import SubmitSuccess from "../SubmitSuccess";
import {
  HandleNavigation,
  HandleDataPreparation,
} from "../../helper/functions";
import { skipLogicData } from "../../helper/customFormRestrictions";
import { getSalesFields } from "../../helper/functions";
export default function Form(props) {
  const dispatch = useDispatch();
  const reduxItems = useSelector((params) => params);
  const {
    generalSettings,
    loading,
    formData,
    currentQuestion,
    currentPage,
    continueStatus,
    currentAction,
    submitAction,
    fields,
    navigationClickEvent,
    clickEvent,
  } = reduxItems;
  // Local storage data of navbar for changing currentQuestion and currentPage
  var localStorageNav = JSON.parse(localStorage.getItem("exportiaNavData"));
  // State to handle email
  const [responseStatus, setResponseStatus] = useState(false);
  // List of navigation pages from API
  var formPages = formData.pagination.pages;
  useEffect(() => {
    const testFormFields = formData.fields.filter(
      (form) => form.pageNumber === currentPage + 1 && form.type !== "page"
    );
    let formFieldsRaw = [];
    if (currentPage !== 7) {
      formFieldsRaw = HandleDataPreparation(testFormFields);
    } else {
      formFieldsRaw = testFormFields;
    }
    dispatch({ type: actions.setFields, fields: formFieldsRaw });
    // change current question when page is changed
    if (currentAction === "prev") {
      if (currentPage === 3) {
        let salesArray = getSalesFields(currentQuestion);
        dispatch({
          type: actions.setCurrentQuestion,
          currentQuestion: salesArray[salesArray.length - 1],
        });
      } else {
        dispatch({
          type: actions.setCurrentQuestion,
          currentQuestion: formFieldsRaw.length - 1,
        });
      }
    } else if (currentAction === "next") {
      dispatch({ type: actions.setCurrentQuestion, currentQuestion: 0 });
    }
    localStorage.setItem(
      "exportiaNavData",
      JSON.stringify({
        currentPage: currentPage,
        currentQuestion: currentQuestion,
      })
    );
    dispatch({ type: actions.setSubmitAction, submitAction: false });
  }, [currentPage]);
  useEffect(() => {
    localStorage.setItem(
      "exportiaNavData",
      JSON.stringify({
        currentPage: currentPage,
        currentQuestion: currentQuestion,
      })
    );
  }, [currentQuestion]);
  useEffect(() => {
    let changeNavigation = HandleNavigation(reduxItems);
    // console.log(changeNavigation);
    if (changeNavigation.continueStatus) {
      dispatch({
        type: actions.setCurrentQuestion,
        currentQuestion: changeNavigation.question,
      });
      dispatch({
        type: actions.setCurrentPage,
        currentPage: changeNavigation.page,
      });
    }
    dispatch({
      type: actions.setContinueStatus,
      continueStatus: changeNavigation.continueStatus,
    });
  }, [navigationClickEvent]);
  const handleSubmit = () => {
    const localStorageData = localStorage.getItem("exportiaUserData");
    if (localStorageData) {
      let localStorageDataNew = JSON.parse(localStorageData);
      let found = fields.some(
        (t) =>
          t.isRequired &&
          !Object.keys(localStorageDataNew).includes(t.id.toString())
      );
      if (found) {
        dispatch({ type: actions.setContinueStatus, continueStatus: false });
        return;
      } else {
        if (localStorageDataNew[88]) {
          if (!validator.isEmail(localStorageDataNew[88])) {
            return;
          }
        }
        if (localStorageDataNew[101]) {
          const re = /^[+]?\d+$/;
          if (!re.test(localStorageDataNew[101])) {
            return;
          }
        }
        if (localStorageDataNew[92]) {
          const regex =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm;
          if (!regex.test(localStorageDataNew[92])) {
            return;
          }
        }
        dispatch({ type: actions.setContinueStatus, continueStatus: true });
        dispatch({ type: actions.setLoading, loading: true });
      }
      dispatch({ type: actions.setSubmitAction, submitAction: true });
      let submitData = { form_id: 1 };
      Object.keys(localStorageDataNew).forEach(function (key) {
        if (typeof localStorageDataNew[key] === "object" && key !== "17") {
          let data = localStorageDataNew[key];
          submitData = { ...submitData, ...data };
        } else {
          submitData[key] = localStorageDataNew[key];
        }
      });
      // Axios send data
      const username = process.env.REACT_APP_REST_API_USERNAME;
      const password = process.env.REACT_APP_REST_API_PASSWORD;
      const token = Buffer.from(`${username}:${password}`, "utf8").toString(
        "base64"
      );
      const url =
        process.env.REACT_APP_GFORM_BASE_URL + "/wp-json/gf/v2/entries/";
      axios({
        method: "post",
        url: url,
        headers: { Authorization: `Basic ${token}` },
        data: submitData,
      })
        .then(function (response) {
          if (response.status === 201) {
            setResponseStatus(response.data.id);
          }
        })
        .catch(function (response) {
          // setResponseData(response.message);
        });
    } else {
      dispatch({ type: actions.setLoading, loading: true });
    }
  };
  useEffect(() => {
    if (responseStatus) {
      let localStorageData = localStorage.getItem("exportiaUserData");
      localStorageData = JSON.parse(localStorageData);
      let email = localStorageData[88];
      const url = `${process.env.REACT_APP_GFORM_BASE_URL}/create-pdf/?id=${responseStatus}&email=${email}`;
      dispatch({ type: actions.setLoading, loading: false });
      localStorage.setItem(
        "exportiaNavData",
        JSON.stringify({ currentPage: 0, currentQuestion: 0 })
      );
      localStorage.removeItem("exportiaUserData");
      axios({
        method: "get",
        url: url,
      }).then(function () {
        window.location.reload();
        dispatch({ type: actions.setLoading, loading: false });
      });
    }
  }, [responseStatus]);
  return (
    <div className="min-h-full main">
      {currentAction === "finish-later" ? (
        <FinishLater />
      ) : submitAction === false ? (
        <>
          <div className="stepper-form-page-wrapper">
            <>
              <QuestionNavigation
                navList={formPages}
                activePage={currentPage}
                currentQuestion={currentQuestion}
                generalSettings={generalSettings}
              />
              <Questions
                fields={fields}
                activePage={currentPage}
                currentQuestion={currentQuestion}
                continueStatus={continueStatus}
              />
              <QuestionFooter
                continueStatus={continueStatus}
                backStatus={
                  currentQuestion === 0 && currentPage === 0 ? false : true
                }
                submitButton={currentPage === 7 ? true : false}
                handleSubmit={handleSubmit}
              />
            </>
          </div>
        </>
      ) : loading === true ? (
        <Loader />
      ) : (
        <>
          <SubmitSuccess generalSettings={generalSettings} />
        </>
      )}
    </div>
  );
}
