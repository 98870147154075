export const skipLogicData = {
  "We only sell direct to our clients": [0, 1, 3],
  "We usually sell direct, but would like to use distributors overseas.": [
    0, 2, 3, 8,
  ],
  "We use distributors to sell (no direct sales to end-users).": [
    0, 2, 3, 4, 5, 7, 8,
  ],
  "We do both sell direct and have distributors as well.": [
    0, 2, 3, 4, 5, 7, 8,
  ],
  "We sell online only. It is our preferred business model.": [0, 1, 3],
  "We sell direct, we have distributors and we also sell online": [
    0, 2, 3, 4, 5, 7, 8,
  ],
};
export const skipLogicId = {
  "We only sell direct to our clients": [50, 51, 53],
  "We usually sell direct, but would like to use distributors overseas.": [
    50, 52, 53, 58,
  ],
  "We use distributors to sell (no direct sales to end-users).": [
    50, 52, 53, 54, 55, 57, 58,
  ],
  "We do both sell direct and have distributors as well.": [
    50, 52, 53, 54, 55, 57, 58,
  ],
  "We sell online only. It is our preferred business model.": [50, 51, 53],
  "We sell direct, we have distributors and we also sell online": [
    50, 52, 53, 54, 55, 57, 58,
  ],
  all: [50, 51, 52, 53, 54, 55, 56, 57, 58],
};
