import * as actionTypes from "./actions";
var localStorageNav = JSON.parse(localStorage.getItem("exportiaNavData"));
export const initialState = {
  currentQuestion:
    localStorageNav && localStorageNav.currentQuestion
      ? localStorageNav.currentQuestion
      : 0,
  currentPage:
    localStorageNav && localStorageNav.currentPage
      ? localStorageNav.currentPage
      : 0,
  currentAction: "",
  submitAction: false,
  currentFormData: 0,
  persistedValue: {},
  navigationStatus: true,
  continueStatus: true,
  loading: false,
  formData: {},
  fields: [],
  navigationClickEvent: true,
  loadHomePage: true,
};
const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.setCurrentPage:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case actionTypes.setCurrentQuestion:
      return {
        ...state,
        currentQuestion: action.currentQuestion,
      };
    case actionTypes.setCurrentAction:
      return {
        ...state,
        currentAction: action.currentAction,
      };
    case actionTypes.setSubmitAction:
      return {
        ...state,
        submitAction: action.submitAction,
      };
    case actionTypes.setCurrentFormData:
      return {
        ...state,
        currentFormData: action.currentFormData,
      };
    case actionTypes.setPersistedValue:
      return {
        ...state,
        persistedValue: action.persistedValue,
      };
    case actionTypes.setNavigationStatus:
      return {
        ...state,
        navigationStatus: action.navigationStatus,
      };
    case actionTypes.setLoading:
      return {
        ...state,
        loading: action.loading,
      };
    case actionTypes.setContinueStatus:
      return {
        ...state,
        continueStatus: action.continueStatus,
      };
    case actionTypes.setGeneralSettings:
      return {
        ...state,
        generalSettings: action.generalSettings,
      };
    case actionTypes.setFormData:
      return {
        ...state,
        formData: action.formData,
      };
    case actionTypes.setCurrentPage:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case actionTypes.setCurrentQuestion:
      return {
        ...state,
        currentQuestion: action.currentQuestion,
      };
    case actionTypes.setFields:
      return {
        ...state,
        fields: action.fields,
      };
    case actionTypes.setNavigationClickEvent:
      return {
        ...state,
        navigationClickEvent: action.navigationClickEvent,
      };
    case actionTypes.setLoadHomePage:
      return {
        ...state,
        loadHomePage: action.loadHomePage,
      };
    default:
      return state;
  }
};
export default customizationReducer;
