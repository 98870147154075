import React from "react";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const { pageData } = props;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link color="primary" onClick={handleClickOpen}>
        {pageData.title ? pageData.title : "Terms & Conditions"}
      </Link>
      {pageData.content && (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <div className="modal-dialog-box-container">
            <div
              className="modal-dialog-box-header"
              style={{ position: "sticky", top: 0 }}
            >
              <h2>{pageData.title ? pageData.title : "Terms & Conditions"}</h2>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                className="model-close-icon"
              >
                <CloseIcon style={{ fontSize: 35 }} />
              </IconButton>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: pageData.content }}
              className="modal-dialog-box-content"
            />
            {/* <div className='modal-dialog-box-content'>
                        {pageData.content}
                    </div> */}
          </div>
        </Dialog>
      )}
    </>
  );
}
