export const setCurrentPage = "setCurrentPage";
export const setCurrentQuestion = "setCurrentQuestion";
export const setCurrentAction = "setCurrentAction";
export const setNavigationClickEvent = "setNavigationClickEvent";
export const setSubmitAction = "setSubmitAction";
export const setNavigationStatus = "setNavigationStatus";
export const setCurrentFormData = "setCurrentFormData";
export const setPersistedValue = "setPersistedValue";
export const setFormData = "setFormData";
export const setFields = "setFields";
export const setContinueStatus = "setContinueStatus";
export const setLoading = "setLoading";
export const setGeneralSettings = "setGeneralSettings";
export const setLoadHomePage = "setLoadHomePage";
