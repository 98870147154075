import React, { useState } from "react";
import { Paper, TextField, Button } from "@material-ui/core";
import axios from "axios";
import "./FinishLater.scss";
import validator from "validator";
import { setCurrentAction } from "../../store/actions";
import { useDispatch } from "react-redux";
export default function Form(props) {
  const [persistedValueFinishLater, setPersistedValueFinishLater] = useState(
    {}
  );
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState({});
  const [submitStatus, setSubmitStatus] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [emailError, setEmailError] = useState("");
  let localStorageData = localStorage.getItem("exportiaUserData");
  localStorageData = JSON.parse(localStorageData);
  let localStorageNav = localStorage.getItem("exportiaNavData");
  localStorageNav = JSON.parse(localStorageNav);
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email) || email === "") {
      setEmailError("");
    } else {
      setEmailError("Invalid email address.");
    }
  };
  function handleChange(event, id) {
    event.preventDefault();
    let value = event.target.value;
    setPersistedValueFinishLater((persistedValueFinishLater) => ({
      ...persistedValueFinishLater,
      [id]: value,
    }));
  }
  function handleSubmit(e) {
    if (
      persistedValueFinishLater.finish_first_name &&
      persistedValueFinishLater.finish_last_name &&
      persistedValueFinishLater.finish_email &&
      emailError === ""
    ) {
      e.preventDefault();
      const submitData = {
        data: persistedValueFinishLater,
        location: localStorageNav,
        form_data: localStorageData,
      };
      const url =
        process.env.REACT_APP_GFORM_BASE_URL + "/wp-json/submit/form-data";
      axios({
        method: "post",
        url: url,
        data: submitData,
      })
        .then(function (response) {
          setFormData(response.data);
          if (formData.status === true) {
            localStorage.setItem(
              "exportiaNavData",
              JSON.stringify({ currentPage: 0, currentQuestion: 0 })
            );
            localStorage.removeItem("exportiaUserData");
            setSubmitStatus(true);
          } else {
            setSubmitStatus(true);
          }
        })
        .catch(function (response) {
          setFormData(response.message);
          setSubmitStatus(true);
        });
    } else {
      setErrorStatus(true);
    }
  }
  return (
    <section className="finish-later-form-wrapper">
      <div className="container">
        <Paper
          elevation={3}
          className={
            submitStatus
              ? "form-container bg-white finish-later-submiteed"
              : "form-container bg-white"
          }
        >
          {submitStatus === true ? (
            <>
              <h2 className="h3">Finish later</h2>
              {formData.status === true ? (
                <>
                  <p className="heading1">Thank you!</p>
                  <p>
                    A link has been sent to your email to resume your progress
                    at anytime
                  </p>
                </>
              ) : (
                <>
                  <p className="heading1">Sorry!</p>
                  <p>
                    There seems to be some problem at the moment. Please try
                    again later.
                  </p>
                </>
              )}
            </>
          ) : (
            <>
              <h2 className="h3">Finish later</h2>
              <p className="sub-heading">
                Register your email to save your progress
              </p>
              <TextField
                label="First Name"
                variant="outlined"
                className="text-field"
                onChange={(e) => handleChange(e, "finish_first_name")}
                error={
                  errorStatus && !persistedValueFinishLater.finish_first_name
                    ? true
                    : false
                }
                helperText={
                  errorStatus && !persistedValueFinishLater.finish_first_name
                    ? "This field is required"
                    : ""
                }
              />
              <TextField
                label="Last Name"
                variant="outlined"
                className="text-field"
                onChange={(e) => handleChange(e, "finish_last_name")}
                error={
                  errorStatus && !persistedValueFinishLater.finish_last_name
                    ? true
                    : false
                }
                helperText={
                  errorStatus && !persistedValueFinishLater.finish_last_name
                    ? "This field is required"
                    : ""
                }
              />
              <div className="d-flex flex-wrap flex-direction-column pos-relative">
                {
                  <span
                    className={emailError === "" ? "d-none" : "error-email"}
                    style={{
                      color: "red",
                      order: "2",
                    }}
                  >
                    {emailError}
                  </span>
                }
                <TextField
                  label="Email"
                  onInput={(e) => validateEmail(e)}
                  variant="outlined"
                  className="email-field"
                  onChange={(e) => handleChange(e, "finish_email")}
                  error={
                    errorStatus && !persistedValueFinishLater.finish_email
                      ? true
                      : false
                  }
                  helperText={
                    errorStatus && !persistedValueFinishLater.finish_email
                      ? "This field is required"
                      : ""
                  }
                />
              </div>
              <Button
                color="primary"
                size="large"
                variant="contained"
                className="save-btn"
                onClick={(e) => handleSubmit(e)}
              >
                Save your progress
              </Button>
            </>
          )}
        </Paper>
      </div>
    </section>
  );
}
