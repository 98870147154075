import React from "react";
import TermsCondition from "./TermsConditions";
import Privacypolicy from "./PrivacyPolicy";
import { useSelector } from "react-redux";
import "./Footer.scss";
export default function Footer() {
  const { generalSettings } = useSelector((params) => params);
  const termsServicePage = generalSettings?.termsServicePage;
  const privacyPolicyPage = generalSettings?.privacyPolicyPage;
  return (
    <>
      <footer className="site-footer">
        <div className="footer-container">
          <ul className="footer-list">
            <li>
              © {new Date().getFullYear()} Exportia Pvt. Ltd. All rights
              reserved.
            </li>
            <ul className="footer-menu">
              {termsServicePage && (
                <li>
                  <TermsCondition pageData={termsServicePage} />
                </li>
              )}
              {privacyPolicyPage && (
                <li>
                  <Privacypolicy pageData={privacyPolicyPage} />
                </li>
              )}
            </ul>
            <li>
              Designed by{" "}
              <a
                className="ebp"
                href="https://ebpearls.com.au/"
                target="_blank"
                rel="noreferrer"
              >
                EB Pearls
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}
