import { createTheme } from "@material-ui/core/styles";

let colorOrange = "#F27251";
let colorWhite = "#fff";
let colorLightGray = "#E0E0E0";
let colorLogoBlue = "#0090D6";
let colorDarkBlue = "#0E7CCE";
const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: "unset",
      },
      containedPrimary: {
        backgroundColor: colorOrange,
        color: colorWhite,
        borderRadius: "2px",
        "&:hover": {
          backgroundColor: colorOrange,
        },
        "&:active": {
          boxShadow: "unset",
        },
      },
      outlinedPrimary: {
        borderColor: colorOrange,
        color: colorOrange,
        "&:hover": {
          borderColor: colorOrange,
          backgroundColor: "unset",
        },
        "&.icon-button": {
          minWidth: "160px",
        },
      },
      sizeSmall: {
        padding: "8px 0",
        minWidth: "120px",
        fontSize: "12px",
        lineHeight: "24px",
      },
      sizeLarge: {
        padding: "18px 0",
        minWidth: "200px",
        fontSize: "16px",
        lineHeight: "24px",
      },
      label: {
        padding: "0",
      },
    },
    MuiCircularProgress: {
      svg: {
        color: colorOrange,
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: colorOrange,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "18px",
      },
    },
    MuiRadio: {
      colorPrimary: {
        "&:hover": {
          backgroundColor: "unset !important",
        },
      },
    },
    MuiChecked: {
      fontSize: "2rem",
      "&:hover": {
        backgroundColor: "unset !important",
      },
    },
    MuiCheckbox: {
      /*borderRadius: '4px',
            borderColor: colorGray,*/
      colorPrimary: {
        "&$checked": {
          color: colorOrange,
        },
        "& span svg": {
          fontSize: "2rem",
        },
      },
      root: {
        "&:hover , &:focus": {
          backgroundColor: "unset !important",
        },
      },
    },
    MuiAccordion: {
      root: {
        marginBottom: 0,
        boxShadow: "unset",
        "&:before": {
          top: "unset",
          bottom: 0,
          backgroundColor: colorLightGray,
          left: 0,
          right: 0,
          height: "1px",
          content: '"" !important',
          opacity: 1,
          position: "absolute",
          transition:
            "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&.Mui-expanded": {
          margin: 0,
          paddingBottom: "30px",
          "&:before": {
            opacity: "1 !important",
          },
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        paddingLeft: 0,
        minHeight: "38px",
        "&.Mui-expanded": {
          minHeight: "38px",
        },
      },
      content: {
        fontSize: "20px",
        lineHeight: "100%",
        fontWeight: "medium",
        color: "#333",
        padding: "18px 0",
        margin: 0,
        marginBottom: "4px",
        "@media ( min-width: 1200px)": {
          fontSize: "25px",
        },
        "&.Mui-expanded": {
          margin: 0,
        },
      },
      expandIcon: {
        color: "#000",
        svg: {
          fontSize: "28px !important",
        },
        "&.Mui-expanded": {
          color: colorLogoBlue,
        },
        marginRight: 0,
        "@media ( min-width: 1200px)": {
          marginRight: "44px",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },
    MuiTextField: {
      root: {
        "& label": {
          color: "#828282",
          fontSize: "16px",
        },
      },
    },
    MuiSelect: {
      root: {
        "& label": {
          color: "#828282",
          fontSize: "16px",
        },
      },
    },
    MuiFormLabel: {
      filled: {
        zIndex: "5 !important",
        color: colorDarkBlue + " !important",
        "& .MuiOutlinedInput-input": {
          backgroundColor: "transparent !important",
        },
      },
    },
  },
});
export default theme;
