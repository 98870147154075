import React, { useState, useEffect } from "react";
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import checkboxIcon from "../../../assets/icons/checkbox-icon.svg";
import checkboxCheckedIcon from "../../../assets/icons/checkbox-checked-icon.svg";
import radioButton from "../../../assets/icons/radio-button.svg";
import radioChecked from "../../../assets/icons/radio-checked.svg";
import { useDispatch } from "react-redux";
import { setCurrentFormData } from "../../../store/actions";
export default function QuestionBox(props) {
  var {
    persistedValue,
    continueStatus,
    fieldData,
    currentQuestion,
    activePage,
  } = props;
  const dispatch = useDispatch();
  const [showTextBox, setShowTextBox] = useState(false);
  const handleOther = (e) => {
    e.persist();
    if (
      (fieldData.isRequired === true && e.target.value === "") ||
      (fieldData.isRequired === false && e.target.value === "other")
    ) {
      setShowTextBox(true);
    } else {
      setShowTextBox(false);
    }
  };
  useEffect(() => {
    if (
      fieldData.type === "radio" &&
      persistedValue[fieldData.id] &&
      fieldData.enableOtherChoice
    ) {
      let newstate = fieldData.choices.map((item, index) =>
        item.value === persistedValue[fieldData.id] ? true : false
      );
      if (!newstate.includes(true)) {
        setShowTextBox(true);
      }
    }
    dispatch({ type: setCurrentFormData, currentFormData: fieldData });
  }, []);

  function requiredText() {
    if (fieldData.isRequired) {
      if (currentQuestion === 1 && activePage === 1) {
        if (
          (!persistedValue[fieldData.id] ||
            persistedValue[fieldData.id].length === 0) &&
          !persistedValue["remove_country"]
        ) {
          return <p className="required-text">This field is required</p>;
        } else {
          return "";
        }
      } else {
        if (
          persistedValue[fieldData.id] &&
          Object.keys(persistedValue[fieldData.id]).length > 0
        ) {
          return "";
        } else {
          return <p className="required-text">This field is required</p>;
        }
      }
    } else {
      if (currentQuestion === 2 && activePage === 1) {
        if (
          (persistedValue[20] && Object.keys(persistedValue[20]).length > 0) ||
          (persistedValue[22] && Object.keys(persistedValue[22]).length > 0) ||
          (persistedValue[23] && Object.keys(persistedValue[23]).length > 0) ||
          (persistedValue[24] && Object.keys(persistedValue[24]).length > 0) ||
          (persistedValue[25] && Object.keys(persistedValue[25]).length > 0) ||
          (persistedValue[26] && Object.keys(persistedValue[26]).length > 0) ||
          (persistedValue[27] && Object.keys(persistedValue[27]).length > 0) ||
          (persistedValue["remove_all_country"] &&
            persistedValue["remove_all_country"] === true)
        ) {
          return "";
        } else {
          return <p className="required-text">This field is required</p>;
        }
      }
    }
  }
  function createFieldGroup() {
    switch (fieldData.type) {
      case "radio":
        return (
          <RadioGroup
            aria-label={fieldData.adminLabel}
            name={fieldData.adminLabel}
            className={"radio-group " + fieldData.cssClass}
            value={persistedValue[fieldData.id] || ""}
            onChange={(event) => props.handleChange(event, fieldData.id)}
          >
            {fieldData.choices.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                onChange={handleOther}
                control={
                  <Radio
                    color="primary"
                    icon={
                      <img
                        src={radioButton}
                        className="checkbox-icon"
                        alt="radio-button-icon"
                      />
                    }
                    checkedIcon={
                      <img
                        src={radioChecked}
                        className="checkbox-icon"
                        alt="radio-button-checked-icon"
                      />
                    }
                  />
                }
                label={
                  item.text.includes("C&D")
                    ? item.text.replace("C&D", "C & D")
                    : item.text
                }
                className={"radio-options form-fields"}
                // onChange={(e) => props.handleChange(e, fieldData)}
              />
            ))}

            {fieldData.enableOtherChoice === true && (
              <FormControlLabel
                onChange={handleOther}
                control={
                  <Radio
                    color="primary"
                    icon={
                      <img
                        src={radioButton}
                        className="checkbox-icon"
                        alt="radio-button-icon"
                      />
                    }
                    checkedIcon={
                      <img
                        src={radioChecked}
                        className="checkbox-icon"
                        alt="radio-button-checked-icon"
                      />
                    }
                    // onChange={(e) => props.handleChange(e, fieldData)}
                  />
                }
                checked={showTextBox ? true : false}
                label="Other"
                value={fieldData.isRequired ? "" : "other"}
                className={"radio-options form-fields other-field"}
              />
            )}
            {showTextBox && (
              <>
                <TextField
                  variant="outlined"
                  className="other-textfield"
                  onChange={(e) => props.handleChange(e, fieldData.id)}
                  value={
                    persistedValue[fieldData.id] &&
                    persistedValue[fieldData.id].length &&
                    persistedValue[fieldData.id] !== "other"
                      ? persistedValue[fieldData.id]
                      : ""
                  }
                />
                {!fieldData.isRequired &&
                  !continueStatus &&
                  fieldData.enableOtherChoice &&
                  (persistedValue[fieldData.id] === "other" ||
                    persistedValue[fieldData.id].length < 1) && (
                    <p className="required-text other">
                      This field is required
                    </p>
                  )}
              </>
            )}
          </RadioGroup>
        );
      case "multiselect":
        return (
          <FormGroup
            className={
              fieldData.cssClass.length > 0
                ? "multiselect-group " + fieldData.cssClass
                : "multiselect-group"
            }
          >
            {fieldData.choices.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    color="primary"
                    icon={
                      <img
                        src={checkboxIcon}
                        className="checkbox-icon"
                        alt="checkbox-icon"
                      />
                    }
                    checkedIcon={
                      <img
                        src={checkboxCheckedIcon}
                        className="checkbox-icon"
                        alt="checkbox-checked-icon"
                      />
                    }
                    onChange={(event) =>
                      props.handleChange(event, fieldData.id, "multiselect")
                    }
                    name={fieldData.adminLabel}
                    checked={
                      persistedValue[fieldData.id] &&
                      persistedValue[fieldData.id].includes(item.value)
                        ? true
                        : false
                    }
                  />
                }
                label={item.text}
                value={item.value}
                size="small"
                className="multiselect-options form-fields"
              />
            ))}
          </FormGroup>
        );
      case "checkbox":
        return (
          <FormGroup
            className={
              fieldData.cssClass.length > 0
                ? "checkbox-group " + fieldData.cssClass
                : "checkbox-group"
            }
          >
            {fieldData.choices.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    color="primary"
                    icon={
                      <img
                        src={checkboxIcon}
                        className="checkbox-icon"
                        alt="checkbox-icon"
                      />
                    }
                    checkedIcon={
                      <img
                        src={checkboxCheckedIcon}
                        className="checkbox-icon"
                        alt="checkbox-checked-icon"
                      />
                    }
                    onChange={(event) =>
                      props.handleChange(
                        event,
                        fieldData.id,
                        "select",
                        fieldData.id + "." + (index + 1)
                      )
                    }
                    name={fieldData.adminLabel}
                    checked={
                      persistedValue[fieldData.id] &&
                      Object.keys(persistedValue[fieldData.id]).includes(
                        fieldData.id + "." + (index + 1)
                      )
                        ? true
                        : false
                    }
                  />
                }
                label={item.text}
                value={item.value}
                size="small"
                className="checkbox-options form-fields"
              />
            ))}
          </FormGroup>
        );
      case "text":
        return (
          <TextField
            label={fieldData.placeholder}
            variant="outlined"
            className="text-field"
            onChange={(e) => props.handleChange(e, fieldData.id)}
            value={
              persistedValue[fieldData.id] &&
              persistedValue[fieldData.id].length
                ? persistedValue[fieldData.id]
                : ""
            }
            error={
              fieldData.isRequired &&
              !continueStatus &&
              !persistedValue[fieldData.id]
                ? true
                : false
            }
            helperText={
              fieldData.isRequired &&
              !continueStatus &&
              !persistedValue[fieldData.id] &&
              "This field is required"
            }
          />
        );
      case "html":
        return (
          fieldData.content && (
            <p className="html-content">{fieldData.content}</p>
          )
        );
      case "select":
        return (
          <div className="d-flex flex-wrap align-items-center pos-relative">
            <Select label={fieldData.label} className="select-field w-100">
              {fieldData.choices.map((item, index) => (
                <MenuItem
                  key={index}
                  onChange={(e) => props.handleChange(e, fieldData.id)}
                  value={
                    persistedValue[fieldData.id] &&
                    persistedValue[fieldData.id].length
                      ? persistedValue[fieldData.id]
                      : ""
                  }
                >
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      case "email":
        return (
          <TextField
            label={fieldData.label}
            variant="outlined"
            onChange={(e) => props.handleChange(e, fieldData.id)}
            value={
              persistedValue[fieldData.id] &&
              persistedValue[fieldData.id].length
                ? persistedValue[fieldData.id]
                : ""
            }
          />
        );
      default:
        return "";
    }
  }
  function createField(fieldData) {
    if (fieldData.type === "select") {
      return (
        <FormControl component="fieldset" variant="outlined">
          <InputLabel className="select-field-label">
            {fieldData.label}
          </InputLabel>
          {createFieldGroup(fieldData)}
        </FormControl>
      );
    } else {
      return (
        <>
          {fieldData.type !== "text" &&
          !continueStatus &&
          activePage === 1 &&
          currentQuestion === 1
            ? requiredText()
            : ""}
          <FormControl component="fieldset">
            {fieldData.cssClass.includes("option-select-none") ? (
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    onChange={(event) =>
                      props.handleChange(event, fieldData.id, "remove-all")
                    }
                    checked={!!persistedValue.remove_country}
                    icon={
                      <img
                        src={radioButton}
                        className="checkbox-icon"
                        alt="radio-button-icon"
                      />
                    }
                    checkedIcon={
                      <img
                        src={radioChecked}
                        className="checkbox-icon"
                        alt="radio-button-checked-icon"
                      />
                    }
                  />
                }
                label="None of the below"
                className={"radio-options form-fields radio-select-none"}
              />
            ) : (
              ""
            )}
            <label
              className={
                fieldData.cssClass.includes("label-blue")
                  ? "stepper-form-label stepper-form-secondary-label h4"
                  : "stepper-form-label h3"
              }
              style={
                fieldData.label.includes("xxx")
                  ? { "white-space": "pre-line" }
                  : {}
              }
            >
              {fieldData.label.replace("xxx", "\n")}
            </label>
            {fieldData.type !== "text" && !continueStatus && fieldData.id != 17
              ? requiredText()
              : ""}
            {createFieldGroup(fieldData)}
          </FormControl>
        </>
      );
    }
  }
  return (
    <>
      {fieldData.cssClass.includes("accordion-item") ? (
        <>
          {fieldData.cssClass.includes("accordion-group") ? (
            <FormControlLabel
              value="remove_all_country"
              control={
                <Radio
                  color="primary"
                  onChange={(event) =>
                    props.handleChange(
                      event,
                      fieldData.id,
                      "remove-all-country"
                    )
                  }
                  value="remove_all_country"
                  checked={!!persistedValue["remove_all_country"]}
                  icon={
                    <img
                      src={radioButton}
                      className="checkbox-icon"
                      alt="radio-button-icon"
                    />
                  }
                  checkedIcon={
                    <img
                      src={radioChecked}
                      className="checkbox-icon"
                      alt="radio-button-checked-icon"
                    />
                  }
                />
              }
              label="None of the below"
              className={
                "radio-options form-fields radio-select-none-accordion"
              }
            />
          ) : (
            ""
          )}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel-" + fieldData.adminLabel + "-content"}
              id={"panel-" + fieldData.adminLabel + "-header"}
            >
              {fieldData.label}
            </AccordionSummary>
            <AccordionDetails>
              {/* {fieldData.isRequired &&
                !continueStatus &&
                !persistedValue[fieldData.id] &&
                fieldData.type !== "text" && (
                  <p className="required-text final-radio-error-text">
                    This field is required
                  </p>
                )} */}
              {createFieldGroup(fieldData)}
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        createField(fieldData)
      )}
    </>
  );
}
